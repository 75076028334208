.hello {
  background-color: var(--primary-color);
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.loader img {
  width: 12.5rem;
  height: 12.5rem;
}

.error {
  color: #f23232;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 0.625rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.d11_apply_btn {
  position: absolute;
}

textarea.form-control {
  height: 16.25rem !important;
}

.fl-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.purple-color {
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
}

.d11-reward-img {
  height: 50%;
}

.d11-reward-coin {
  height: 30%;
  background-color: rblueed;
}

.d11-reward-btn {
  font-size: 1.25rem;
  font-weight: 500;
  font-family: var(--font-body-bold);
  border-radius: 0 0 10px 10px;
}

.d11-reward-coin {
  padding: 0 1.25rem;
}

.d11-reward-coin h4 {
  font-size: 1.25rem;
  font-family: var(--font-body-bold);
}

.d11-reward-coin .second {
  display: flex;
  justify-content: space-between;
}

.d11-reward-img img {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
}

.d11-reward {
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
  background: var(--primary-color-dark-blue);
  padding: 0;
}

.d11-reward-title {
  font-size: 36px;
  font-family: var(--font-body-bold);
}

.d11-recenttran {
  color: var(--color-black);
  font-family: var(--font-body-bold);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25;
  /* 125% */
  letter-spacing: 0.1px;
  border-radius: 100px;
  background: var(--primary-color-dark);
  display: flex;
  width: 15.6875rem;
  height: 2.5rem;
  padding: 0.625rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  outline: none;
  border: none;
}

.d11-recenttran:hover {
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  color: var(--color-heading);
}

.d11-rewards-status {
  position: absolute;
  top: 0;
  right: 0;
  width: 110px;
  height: 30px;
  border-radius: 0px 10px;
  background: var(--primary-color-dark);
}

html[dir="rtl"] .d11-rewards-status {
  right: inherit;
  left: 0;
}

.d11-cancel-btn {
  border-radius: 100px;
  border: 2px solid var(--body-color);
  color: var(--color-heading);
}

.d11-logout-text {
  text-align: center;
  margin-bottom: 20px;
}

.d11-logout-text h6 {
  color: var(--color-heading);
  font-family: var(--font-body-bold);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.111;
  letter-spacing: 0.1px;
}

.d11-profile-edit {
  margin-bottom: 20px;
}



.d11-phoneCode {
  background-color: var(--bs-body-bg);
  --bs-form-select-bg-img: url(../images/downArrow-black-svg.svg);
  background-size: 1.5rem 1.5rem;
  background-position: right 1.125rem center;
  height: 3.75rem;
  border-radius: 50px;
  border: 1px solid var(--body-color);
  color: var(--color-heading);
  padding: 1.25rem;
  font-size: 0.875rem;
  outline: none;
  box-shadow: none;
}

html[dir="rtl"] .d11-phoneCode {
  background-position: left 1.125rem center;
}

.form-placeholder::placeholder {
  color: var(--color-white) !important;
}

.d11-profile-form .form-select:disabled,
.d11-profile-form .form-control:disabled {
  /* color: var(--color-black); */
  background-color: var(--bs-body-bg);
  color: var(--color-heading);
  border: 1px solid var(--body-color);
  /* border: none; */
}

.d11-phone-code:disabled {
  /* background-color: var(--secondary-color-emphasis); */
  background-color: var(--bs-body-bg);
}

.d11-phone-code-golden:disabled {
  background-color: var(--body-color);
  color: var(--color-white);
  opacity: 0.5;
}

.d11-points ul {
  width: 100%;
  border-radius: 100px;
  background-color: var(--primary-color-dark-blue);
  padding: 0;
  margin-bottom: 10px;
}

.d11-points-li {
  list-style: none;
  height: 3.5625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.625rem;
}

.active-li {
  background-color: var(--primary-color-red);
  border-radius: 100px;
}

.d11-points-point {
  width: 100%;
  /* background-color: var(--secondary-color-emphasis); */
  height: 3.375rem;
  border-radius: 20px;
  margin-bottom: 0.4375rem;
  color: var(--color-heading);
}

.d11-points-point ul {
  background-color: transparent;
  border: 1px solid var(--body-color);
}

.d11-points-li1 {
  list-style: none;
  height: 3.375rem;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
}

.d11-freeGame-title {
  color: #fff;
  font-family: var(--font-body-bold);
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 55.556% */
  letter-spacing: 0.1px;
}

.d11-freeGame-rectangle {
  max-width: 1500px;
  width: 100%;
  height: 48.875rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  background: #697582;
}

.d11-freeGame-title {
  color: #fff;
  font-family: var(--font-body-bold);
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.tournametParticipationCard {
  cursor: pointer;
}

.addPlayerCard .form-check {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.btn-copy {
  background-image: url(../images/copy-icon-svg.svg);
  background-position: right 20px center;
  background-repeat: no-repeat;
  text-align: left;
}

html[dir="rtl"] .btn-copy {
  background-position: left 20px center;
  text-align: right;
}

.btn-share {
  background-image: url(../images/share-nodes.png);
  background-position: right 20px center;
  background-repeat: no-repeat;
  text-align: left;
  background-size: 1.5rem auto;
}

html[dir="rtl"] .btn-share {
  background-position: left 20px center;
  text-align: right;
}

.d11-refer-color {
  color: var(--primary-color-red);
}

.favorite-game-section {
  max-height: 29rem;
  overflow-y: auto;
}

.favorite-game-section .active {
  border: var(--primary-color-red) 3px solid;
}

.css-1u9des2-indicatorSeparator {
  display: none;
  padding: 0;
}

.css-1nmdiq5-menu {
  background: var(--secondary-color-emphasis) !important;
  border: none !important;
  color: var(--color-white) !important;
  box-shadow: none !important;
}

.d11-title-dropdown {
  background-color: var(--secondary-color-emphasis);
  color: var(--color-white);

  --bs-form-select-bg-img: url(../images/downArrow-svg.svg);
  background-size: 1.5rem 1.5rem;
  background-position: right 1.125rem center;
}

html[dir="rtl"] .d11-title-dropdown {
  background-position: left 1.125rem center;
}

.d11-final-text {
  color: var(--primary-color-red);
  text-align: center;
  font-family: var(--font-body-bold);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 83.333% */
  letter-spacing: 0.1px;
  height: 30px;
}

.tournamentModal h4 {
  font-weight: 700;
  color: var(--color-heading);
}

.tournamentModal .form-check .form-check-input {
  border: 1px solid var(--color-heading);
}

.d11-round-text {
  background-color: var(--primary-color-red);
  color: var(--color-white);
}

.d11-tournament-coin-img {
  width: 1.15rem;
  height: 1.15rem;
}

.d11-codecpro {
  font-family: var(--font-body-bold) !important;
  color: var(--color-heading);
}

.d11-text-center {
  text-align: center;
}

.d1-otp-subText {
  color: #fff;
  font-family: var(--font-body) !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 100 !important;
  line-height: 20px;
  /* 83.333% */
  letter-spacing: 0.1px;
  text-align: center;
}

.d11-otp-resend {
  color: var(--color-heading);
}

.d11-otp-resend-timer {
  color: var(--color-heading);
  opacity: 0.5;
}

.tH-360 {
  height: 190px;
}

.d11-country-flag {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.uploadImage-box {
  height: 16.25rem !important;
  padding: 1.25rem !important;
  background-color: transparent !important;
  border: 1px solid var(--secondary-color-emphasis) !important;
  resize: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.uploadImage-box img {
  height: 15.25rem !important;
  width: 100%;
}

.newsCard .newsDescription {
  color: #aaaaaa !important;
}

.cardLinks a {
  cursor: pointer;
  color: var(--color-white);
}

.uploadOuter input[type="file"] {
  position: absolute;
  height: 20%;
  width: 20%;
  opacity: 0;
  top: 0;
  left: 0;
}

html[dir="rtl"] .uploadOuter input[type="file"] {
  left: inherit;
  right: 0;
}

/* .newsCard .card-img img {
  min-height: 12rem;
  max-height: 12rem;
} */
.pagination-container {
  overflow-x: auto;
  white-space: nowrap;
}

.d11-pagination {
  display: inline-flex;
  /* Ensure the buttons stay in a row */
}

.newsCardDetails .card-img img {
  min-height: 25rem;
  max-height: 25rem;
  object-fit: cover;
}

.newsCardDetails .newsDescription {
  color: #ffffff !important;
}

.newsCardDetails {
  --bs-card-bg: var(--dashboard-bg);
  --bs-card-border-radius: 10px;
  --bs-card-inner-border-radius: 10px;
  --bs-card-border-width: 0;
  --bs-card-title-color: var(--color-white);
  --bs-card-spacer-y: 0.625rem;
  --bs-card-spacer-x: 0.625rem;
  --bs-card-color: var(--color-white);
}

.d11-profile-level {
  color: var(--body-color);
  font-size: 1rem;
}

.primary-disabled {
  background-color: var(--primary-color-dark);
  outline: none;
  border: none;
}

.primary-disabled:hover,
.primary-disabled:disabled {
  background-color: var(--dark-popup) !important;
  opacity: 1 !important;
  color: #fff !important;
}

.d11-level-hover:hover {
  color: var(--accent-color);
}

.collegeDetailsCard {
  --bs-card-bg: var(--dark-color);
  --bs-card-color: var(--color-white);
  --bs-card-title-color: var(--color-white);
  --bs-heading-color: var(--color-white);
  --bs-card-spacer-y: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collegeDetailsCard .card-body {
  max-height: 58.125rem;
  overflow: auto;
  padding-right: 1.5625rem;
  margin-right: -0.625rem;
  width: 100%;
}

html[dir="rtl"] .collegeDetailsCard .card-body {
  padding-right: 0;
  margin-right: 0;
  padding-left: 1.5625rem;
  margin-left: -0.625rem;
}

.collegeDetailsCard ul li {
  padding: 1.25rem 0;
  margin-bottom: 8px;
  border: 1px solid #707070;
  transition: all 0.3s;
}

html[dir="rtl"] .recharts-surface {
  margin: 0 10px !important;
}

html[dir="rtl"] .recharts-default-legend {
  text-align: right !important;
}

.modal .dailyLoginDetailsCard .card-body {
  max-height: 40rem;
}

.cursor {
  cursor: pointer;
}

.d11-pagination {
  /* position: absolute; */
  right: 0;
  display: flex;
  /* width: 272px; */
  height: 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  margin-top: auto;
  bottom: 0;
}

html[dir="rtl"] .d11-pagination {
  right: inherit;
  left: 0;
  align-items: flex-end;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
}

.pagination li {
  margin: 0 5px;
}

.pagination li button,
.d11-pagination button {
  padding: 5px 10px;
  border: 1px solid var(--color-heading);
  background-color: transparent;
  cursor: pointer;
  color: var(--color-heading);
  border-radius: 4px;
  width: auto;
  height: 32px;
  outline: none;
}

.pagination li button:hover,
.d11-pagination button:hover {
  border: 1px solid var(--primary-color-red);
  background-color: transparent;
  color: var(--primary-color-red);
}

.pagination li.active button:hover {
  border: 1px solid var(--color-black);
  color: var(--color-white);
}

.pagination li.active button {
  background-color: var(--pagination-color);
  color: var(--color-white);
}

.d11-pagination button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d11-pagination button img {
  width: 10px;
  height: 15px;
}

.margin-left-btn {
  margin-left: 0 !important;
}

html[dir="rtl"] .margin-left-btn {
  margin-right: 0 !important;
}

.owl-prev {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3px !important;
  margin-left: 20px !important;
  /* display: block !important; */
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 20px !important;
  align-items: center;
  justify-content: center;
  display: flex !important;
}

html[dir="rtl"] .owl-prev {
  margin-left: 0 !important;
  margin-right: 20px !important;
}

.owl-prev span {
  font-size: 3rem;
}

.owl-next span {
  font-size: 3rem;
}

.owl-next {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3px !important;
  /* left: 26em; */
  right: 0;
  margin-left: 20px !important;
  /* display: block !important; */
  background-color: transparent !important;

  color: #fff !important;
  border-radius: 20px !important;
}

html[dir="rtl"] .owl-next {
  right: inherit;
  left: 0;
  margin-left: 0 !important;
  margin-right: 20px !important;
}

.form-check-terms {
  font-size: 0.9rem;
  margin-left: 10px;
  color: var(--color-heading) !important;
  font-weight: 400;
}

.form-check-terms a {
  color:  var(--color-heading) !important;
  font-weight: 400;
  text-decoration: none;
}

html[dir="rtl"] .form-check-terms {
  margin-right: 10px;
  margin-left: 0;
}

.auth-nav.nav .nav-link {
  font-family: var(--font-body-bold);
}

.Verification_number {
  font-size: 2rem;
  text-align: center;
  margin-top: 0;
}

/* .d11-featured-bg {
  padding: 0.625rem 0.625rem 1.25rem;
  background-color: var(--secondary-color-emphasis);
  border-radius: 10px;
  margin-top: 0.625rem;
  height: 260px;
  position: absolute;
  bottom: 0;
  width: 100%;
} */

.d11-featured-bg {
  background-color: var(--secondary-color-emphasis);
  border-radius: 10px;
  bottom: 0;
  height: 14.8rem;
  margin-top: 0.625rem;
  padding: 0.625rem 0.625rem 1.25rem;
  position: absolute;
  width: 100%;
}

.featuredGameCard .card-img::after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(7, 11, 40, 0) 0%, #13181e 100%);
  border-radius: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

html[dir="rtl"] .featuredGameCard .card-img::after {
  left: inherit;
  right: 0;
}

.d11-claim-btn {
  width: 7.5rem;
  height: 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d11-premium {
  font-family: var(--font-titles);
}

.d11-premium-button {
  font-size: 1rem;
  background: linear-gradient(90deg, #cfb279, #f1dcb1, #b39559) !important;
  color: 070B28;
}

.d11-premium-button:hover {
  border: 1px solid var(--primary-color-dark);
}

.d11-premium-h6 {
  font-family: var(--font-body);
  text-align: center;
}

.recharts-legend-item {
  border: none !important;
  height: 30px !important;
}

.recharts-legend-wrapper {
  top: 10% !important;
  left: 50%;
}

html[dir="rtl"] .recharts-legend-wrapper {
  left: inherit;
  right: 50%;
}

.d11-insights-color {
  color: var(--primary-color-red);
  text-decoration: underline;
  cursor: pointer;
}

.d11-ticket-desc {
  overflow: hidden;
}

.d11-ticket-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-picker {
  color: var(--color-black) !important;
}

.MuiPickersLayout-contentWrapper {
  background-color: var(--secondary-color);
  color: var(--color-black);
}

.MuiDayCalendar-header span,
.MuiSvgIcon-root,
.MuiButtonBase-root-MuiButton-root {
  color: var(--color-black) !important;
}

.MuiButtonBase-root:not(.Mui-selected) {
  color: var(--color-black)
}

.MuiIconButton svg {
  color: var(--color-black) !important;
}

.MuiOutlinedInput-input {
  color: var(--color-heading) !important;
  border: none !important;
  outline: none !important;
}

.MuiOutlinedInput-notchedOutline {
  /* border: none !important; */
  border: 1px solid var(--body-color) !important;
  border-radius: 50px !important;
}

.MuiPickersLayout-actionBar {
  background-color: var(--secondary-color);
  color: var(--color-white) !important;
}

.Mui-selected {
  background-color: var(--primary-color-emphasis) !important;
}

html[dir="rtl"] .MuiSelect-root {
  text-align: right;
}

html[dir="rtl"] .MuiSelect-root::after {
  float: left;
}

.d11-payment-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboardMain {
  overflow-x: hidden;
}

.loader .modal-content {
  background-color: transparent !important;
}

.ps_avatar_op {
  position: absolute;
  background: #3e4b5a;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  border: none;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  /* bottom: 0.1rem; */
  top: 0;
}

.ps_avatar_op img {

  position: absolute;
  top: 0;
  right: 5%;
  opacity: 1 !important;
}

html[dir="rtl"] .ps_avatar_op img {
  right: inherit;
  left: 5%;
}

.f-1 {
  font-size: 1.5rem;
}

.edit-dis:disabled {
  color: #fff !important;
}

input:-internal-edge-excel-like-autofill-previewed {
  color: #fff !important;
}

input:focus {
  color: #fff;
}

.hover-primary:hover {
  background-color: var(--primary-color);
}

.text-ellipsis {
  display: block;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fs-65 {
  font-size: 0.65rem;
}

.img-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1rem;
}

.tournament-accordion .accordion-header {
  height: 4rem;
  background-color: var(--dark-color);
  border-radius: 1rem !important;
}

.tournament-accordion .accordion-button {
  height: 100%;
  padding: 0;
  background: var(--primary-color-light) !important;
}

.tournament-accordion .accordion-button::after {
  display: none;
}

.tournament-accordion .accordion-body {
  margin-top: -1rem;

  /* border-radius: 1rem;
   */
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 1rem 0 !important;
  transition: none;
  background-color: var(--content-color);
}

.tournament-accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.tournament-accordion .accordion-item {
  margin-bottom: 0 !important;
}

.tournament-accordion .accordion-item:last-of-type {
  border-radius: 1rem !important;
}

.tournament-accordion .accordion-item:first-of-type .accordion-button {
  /* border-radius: 1rem; */
}

.tournament-accordion .card-header {
  border: none !important;
}

.MuiPickersYear-yearButton {
  padding: 5px !important;
}

.d11-profile-edit input:disabled[type="text"],
.d11-profile-edit input:disabled[type="date"] {
  /* background: var(--disabled); */
  /* background-color: var(--secondary-color-emphasis);
  color: var(--color-black);
  border: none; */
  background-color: var(--bs-body-bg);
  color: var(--color-heading);
  border: 1px solid var(--body-color);
}

.d11-phone-code-golden input:disabled[type="text"],
.d11-phone-code-golden input:disabled[type="date"] {
  /* background: var(--disabled); */
  background-color: var(--body-color);
  color: var(--color-white);
  border: none;
  opacity: 0.5;
}

.d11-phone-code-golden .form-control {
  background-color: transparent;
  color: white;
}

.MuiOutlinedInput-input:disabled {
  color: var(--color-white);
  border: none !important;
  outline: none !important;
  border-radius: 30px;
  -webkit-text-fill-color: var(--color-heading) !important;
  background-color: transparent !important;
  /* opacity: 0.7 !important; */
}


.MuiFormControl-root {
  background: var(--bs-body-bg);
  color: var(--color-heading);
}

.d11-profile-edit .MuiFormControl-root {
  height: 100%;
  /* background: var(--color-white); */
  color: var(--color-heading);
  border: 1px solid var(--body-color);
}

.d11-profile-edit .MuiOutlinedInput-root {
  height: 100%;
}



.tournament-accordion .accordion-button:not(.collapsed),
.accordion-collapse {
  transition: all 5ms ease-in-out;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.tournament-accordion .accordion-button:not(.collapsed) .qulifiedLink span {
  background-image: url("../new-images/arrow-circle-blue.svg");
  transform: none;
  width: 22px;
  height: 22px;
}

.tournament-accordion .accordion-button .qulifiedLink span {
  background-image: url("../new-images/arrow-circle-blue.svg");
  transform: rotate(-90deg);
  width: 22px;
  height: 22px;
}

.f-65 {
  font-size: 0.65rem;
}

.contactInput:-internal-edge-excel-like-autofill-previewed {
  color: #fff !important;
  background-color: transparent;
}

.contactInput:-internal-autofill-selected {
  background-color: transparent !important;
  color: #fff !important;
}

.contactInput:-webkit-autofill,
.contactInput:-webkit-autofill:hover,
.contactInput:-webkit-autofill:focus,
.contactInput:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px black inset !important;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}

.MuiPickersCalendarHeader-label,
.MuiPickersCalendarHeader-labelContainer,
.MuiPaper-root {
  /* opacity: 1; */
  transition: 50ms ease-in-out 0ms !important;
}

.MuiPickersFadeTransitionGroup-root {
  transition: none !important;
}

/* @media screen and (max-width: 1400px) {
  .auth-nav.nav .nav-link {
    width: 10rem;
  }
} */
/* @media screen and (max-width: 1200px){
  .col-md-8{
    width: 70%;
  }
 
} */

.text-align-left {
  text-align: left;
}

html[dir="rtl"] .row-reverse {
  direction: rtl;
}

.text-ellipsis_contest {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis_leaderboard {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-25 {
  width: 1.4rem;
}

.px-10 {
  padding-right: 8rem;
}

html[dir="rtl"] .px-10 {
  padding-left: 10rem;
  padding-right: 0;
}

.scrim-footer {
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scrim-footer-join-btn {
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
}

.Toastify__toast-container--top-center {
  width: 25rem !important;
}

@media screen and (max-width: 985px) {
  html[dir="rtl"] .add-button {
    right: 78% !important;
    /* left:0; */
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-center {
    width: 100% !important;
  }
}

@media screen and (max-width: 399px) {
  .d11-profile-level {
    font-size: 0.9rem;
  }

  .text-ellipsis_leaderboard {
    width: 6rem;
  }

  html[dir="rtl"] .add-button {
    right: 75% !important;
    /* left:0; */
  }
}


.add-button {
  position: absolute;
  right: 1%;
  top: 0px;
  height: 100%;
  line-height: 1;
}

html[dir="rtl"] .add-button {
  right: 69%;
}


.d11-profile-edit .MuiOutlinedInput-root.Mui-disabled {
  background: var(--bs-body-bg);
  border-radius: 50px;
  color: var(--color-heading) !important;
  border: none;

}

.d11-phone-code-golden .MuiOutlinedInput-root.Mui-disabled {
  background: var(--body-color);
  border-radius: 50px;
  color: var(--color-white) !important;
  border: none;

}

html:lang(hi) .form-check-label {
  font-size: 1rem;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(../images/cancel-svgrepo-com.svg);
  background-size: 10px 10px;
  margin-right: 2rem;
}
.following-btn:disabled{
  background-color: var(--primary-color-red) !important;
}

.text-ellipsis-allgames{
 width: 12rem;
}

@media screen and (max-width: 1799px){
  .text-ellipsis-allgames{
    width: 8rem;
   }
}
.fullDisabled{
  background-color: var(--primary-color-emphasis) !important;
  color: #000 !important;
  opacity: 1 !important;
  border: none;
}

.dividerBlue::after{
  background-color: var(--primary-color-dark-blue) !important;
}