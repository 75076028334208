.radio-buttons {
	width: 100%;
	text-align: center;
	display: flex;
	overflow: overlay;
	gap: 2rem;
	margin-bottom: 10px;
}

.custom-radio input {
	display: none;
}

.radio-btn {
	margin: 10px 0;
	width: 100px;
	height: 180px;
	border: 3px solid transparent;
	display: inline-block;
	border-radius: 10px;
	display: flex;
	gap: 5rem;
	position: relative;
	text-align: center;
	/* box-shadow: 0 0 20px #c3c3c367; */
	cursor: pointer;
	border: 2px solid #aaa;
}

.radio-btn>i {
	font-size: 20px;
	position: absolute;
	top: 0px;
	left: 80%;
	transform: translateX(-50%) scale(2);
	border-radius: 50px;
	padding: 3px;
	transition: 0.5s;
	pointer-events: none;
	opacity: 0;
}

.radio-btn .hobbies-icon {
	width: 150px;
	height: 150px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.radio-btn .hobbies-icon img {
	display: block;
	width: 64px;
	height: 120px;
	margin-bottom: 0.2rem;
}

.radio-btn .hobbies-icon i {
	color: #ffdae9;
	line-height: 80px;
	font-size: 60px;
}

.radio-btn .hobbies-icon h3 {
	color: #f3f3f3;
	font-size: 14px;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-top: 10px;
}

.custom-radio input:checked+.radio-btn {
	border-radius: 4px;
	border: 1px solid #aaa;
	background-color: var(--primary-color);
}

.custom-radio input:checked+.radio-btn>i {
	opacity: 1;
	transform: translateX(-50%) scale(1);
}

.radio_box {
	border-radius: 10px;
	/* opacity: 0.2; */
	background: #3e4b5a;
	width: 500px;
	height: 220px;
	padding: 0 3.6rem;
}

@media screen and (max-width: 991px) {
	body::before {
		background-position: 350% center;
		background-size: 640px auto;
	}

	.border-start {
		border-left: 0 !important;
	}

	/* .modal {
	  --bs-modal-width: 600px;
	} */

	/* .modal .btn-close {
	  width: 2rem;
	  height: 2rem;
	} */
}

/* .auth-content form .form-check .form-check-input {
	  width: 1.5rem;
	  height: 1.5rem;
	  border-radius: 25px;
	  box-shadow: none;
	  outline: none;
	  margin-left: -2.125rem;
	  margin-top: 0;
	  background-color: transparent;
  
  } */

.text-primary {
	color: var(--primary-color) !important;
}

.form-check {
	padding-left: 2.125rem;
}

html[dir="rtl"] .form-check {
	padding-left: 0;
	padding-right: 2.125rem;
}

.form-check .form-check-input {
	margin-left: -2.125rem;
	margin-top: 0;
	outline: none;
	box-shadow: none;
}

html[dir="rtl"] .form-check .form-check-input {
	margin-left: 0;
	margin-right: -2.125rem;
}

.form-check-input {
	width: 1.5rem;
	height: 1.5rem;
	background-color: transparent;
}

.form-check-input:checked {
	background-color: transparent;
	border-color: var(--primary-color-red);
	background-size: 2.125rem auto;
}

.premium-circle .form-check-input:checked {
	background-color: transparent;
	border-color: var(--golden-color);
	background-size: 2.125rem auto;
}

/* .form-check-input:checked[type="radio"] {
	--bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ff9933'/%3e%3c/svg%3e");
  } */

.form-check.form-check-primary .form-check-input {
	border-color: var(--secondary-color-emphasis);
}

.form-check.form-check-primary .form-check-input:checked {
	background-size: 2.125rem auto;
	border-color: var(--primary-color-red);
}

.form-check.form-check-primary .form-check-input:checked[type="radio"] {
	--bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f92c2c'/%3e%3c/svg%3e");
}

input[type="date"] {
	position: relative;
}

.form-control[type="date"]::-webkit-calendar-picker-indicator {
	background-image: url(../images/calendar-icon-svg.svg);
	background-size: 1.875rem auto;
	width: 1.875rem;
	height: 1.875rem;
}

.form-control[type="time"]::-webkit-calendar-picker-indicator {
	background-image: url(../images/time-icon.png);
	background-size: 1.875rem auto;
	width: 1.875rem;
	height: 1.875rem;
}

.summaryCard {
	--bs-card-title-color: var(--primary-color);
	--bs-card-border-width: 0;

	/* --bs-card-bg: var(--secondary-color-emphasis); */
	--bs-card-border-radius: 10px;
	--bs-card-spacer-y: 1.25rem;
	--bs-card-spacer-x: 1.25rem;
	--bs-card-color: var(--color-white);
}

.gameInfoCard {
	--bs-card-title-color: var(--primary-color);
	--bs-card-border-width: 0;

	--bs-card-bg: var(--dark-color);
	--bs-card-border-radius: 20px;
	--bs-card-spacer-y: 1.25rem;
	--bs-card-spacer-x: 1.25rem;
	--bs-card-color: var(--color-white);
}

.gameInfoCard .dividersX {
	position: relative;
}

.gameInfoCard .dividersX::before {
	content: "";
	height: 2.5rem;
	width: 2px;
	position: absolute;
	left: -1px;
	top: 0;
	background-color: var(--color-black);
}

.gameInfoCard .dividersX::after {
	content: "";
	height: 2.5rem;
	width: 2px;
	position: absolute;
	right: -1px;
	top: 0;
	background-color: var(--color-black);
}

html[dir="rtl"] .gameInfoCard .dividersX::after {
	right: inherit;
	left: -1px;
}

.gameInfoCard .dividersRight {
	position: relative;
}

.gameInfoCard .dividersRight::after {
	content: "";
	height: 2.5rem;
	width: 2px;
	position: absolute;
	right: -1px;
	top: 0;
	background-color: var(--color-black);
}

.players .dividersRight::after {
	content: "";
	height: 2.5rem;
	width: 2px;
	position: absolute;
	right: -1px;
	top: 0;
	background-color: var(--color-white);
}

html[dir="rtl"] .gameInfoCard .dividersRight::after {
	right: inherit;
	left: -1px;
}

.card-status {
	background-color: var(--primary-color-dark);
	color: var(--color-white);
	font-size: 1.25rem;
	text-transform: uppercase;
	border-radius: 40px;
	line-height: 2.5rem;
	height: 2.5rem;
	padding: 0 2.5rem;
	position: absolute;
	top: -1.875rem;
	left: 50%;
	transform: translateX(-50%);
}

@media screen and (max-width: 1999px) {
	html {
		font-size: 16px;
	}
}

.tournamentModal .form-check {
	margin-bottom: 1.25rem;
	padding-bottom: 1.25rem;
	border-bottom: 1px solid var(--color-heading);
}

.tournamentModal .list-group-flush>.list-group-item:last-child {
	border-bottom-width: 1px;
}

.tournamentRoundCard {
	--bs-card-bg: var(--dark-color);
	--bs-card-color: var(--color-white);
	--bs-card-title-color: var(--color-white);
	--bs-card-border-radius: 20px;
	/* --bs-border-color: #fff !important; */
}

.tournamentRoundCard .card-header {
	position: relative;
	padding-right: 9.375rem;
	padding-top: 1.25rem;
	padding-left: 1.25rem;
	padding-bottom: 0.9375rem;
}

html[dir="rtl"] .tournamentRoundCard .card-header {
	padding-left: 9.375rem;
	padding-right: 1.25rem;
}

.btn-outline-primary {
	--bs-btn-color: var(--primary-color-dark-blue);
	--bs-btn-border-color: var(--primary-color-dark-blue);
	--bs-btn-hover-color: var(--primary-color-dark-blue);
	--bs-btn-hover-bg: var(--color-white);
	--bs-btn-hover-border-color: var(--primary-color-dark-blue);
	--bs-btn-active-color: var(--primary-color-dark-blue);
	--bs-btn-active-bg: var(--color-white);
	--bs-btn-active-border-color: var(--primary-color-dark-blue);
	--bs-btn-active-shadow: none;
	--bs-btn-disabled-color: var(--primary-color-dark-blue);
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: var(--primary-color-dark-blue);
}

.manageYourContestCard {
	--bs-card-title-color: var(--white-color);
	--bs-card-border-width: 1px;
	--bs-card-border-color: var(--dark-color);

	/* --bs-card-bg: var(--dark-color); */
	--bs-card-border-radius: 4px;
	--bs-card-spacer-y: 0.75rem;
	--bs-card-spacer-x: 0.75rem;
	--bs-card-color: var(--color-black);
	transition: all 0.3s;
}

.manageYourContestCard.active {
	background-color: #DCE3E8;
}

.manageYourContestCard .btn {
	min-width: 8.8125rem;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
	color: var(--bs-btn-disabled-color);
	pointer-events: none;
	background-color: var(--primary-color-dark-blue);
	border-color: var(--primary-color-dark-blue);
	opacity: var(--bs-btn-disabled-opacity);
}

.fs-4 {
	font-size: 1.125rem !important;
}

.leaderBoardDetailCard {
	--bs-card-bg: transparent;
	--bs-card-color: var(--color-white);
	--bs-card-title-color: var(--color-white);
	--bs-card-border-radius: 0;
	--bs-heading-color: var(--color-white);
	--bs-border-radius: 10px;
	--bs-card-spacer-y: 0;
	--bs-card-border-width: 0px;
}

.complete-btn {
	margin: -2rem;
}

.token {
	display: flex;
	padding-left: 3rem;
}

html[dir="rtl"] .token {
	padding-left: 0;
	padding-right: 3rem;
}

.upload-title {
	border-radius: 4px;
	background: var(--color-white);
	padding: 0.5rem;
}

.input-file {
	position: relative;
	overflow: hidden;
	padding: 0;
	display: block;
	max-width: 100%;
	cursor: pointer;
}

.input-file .btn {
	white-space: nowrap;
	display: inline-block;
	margin-right: 1em;
	vertical-align: top;
}

html[dir="rtl"] .input-file .btn {
	margin-right: 0;
	margin-left: 1em;
}

.input-file:after {
	content: "";
	display: block;
	clear: both;
}

.input-file input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: -100px;
	z-index: -1;
}

.formDropdown .dropdown-toggle {
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--secondary-color-emphasis);
	border: none;
	border-radius: 40px;
	color: var(--color-white);
	padding: 0 1.25rem;
	font-size: 0.875rem;
	height: 3.75rem;
	width: 100%;
}

.formDropdown .dropdown-toggle::after {
	background-image: url(../images/downArrow-svg.svg);
	width: 1.5rem;
	height: 1.5rem;
	border: none;
	background-position: center;
	background-size: 1.5rem auto;
	vertical-align: middle;
}

.formDropdown .dropdown-toggle.show {
	border-radius: 20px 20px 0 0;
}

.formDropdown .dropdown-menu {
	--bs-dropdown-border-radius: 0 0 20px 20px;
	--bs-dropdown-border-width: 0;
	--bs-dropdown-bg: var(--secondary-color-emphasis);
	--bs-dropdown-color: var(--color-white);
	--bs-dropdown-link-color: var(--color-white);
	--bs-dropdown-padding-x: 1.25rem;
	--bs-dropdown-padding-y: 0.625rem;
	--bs-dropdown-item-padding-x: 1.875rem;
	--bs-dropdown-item-padding-y: 1.0625rem;
	--bs-dropdown-font-size: 1.125rem;
	--bs-dropdown-link-hover-color: var(--primary-color);

	width: 100%;
	padding-bottom: 1.25rem;
}

.formDropdown .dropdown-menu li {
	margin-bottom: 5px;
}

.formDropdown .dropdown-menu .dropdown-item {
	background-color: var(--dashboard-bg);
	font-family: var(--font-body-bold);
}

textarea.form-control {
	height: 16.25rem;
	padding: 1.25rem;
	/* background-color: transparent; */
	border: 1px solid var(--secondary-color-emphasis);
	resize: none;
}

.uploadImage-box {
	height: 16.25rem;
	padding: 1.25rem;
	background-color: transparent;
	border: 1px solid var(--secondary-color-emphasis);
	resize: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.uploadOuter input[type="file"] {
	position: absolute;
	height: 20%;
	width: 20%;
	opacity: 0;
	top: 0;
	left: 0;
}

.draging {
	transform: scale(1.1);
}

#preview {
	text-align: center;
}

#preview img {
	max-width: 40%;
}

.btn-gradient {
	background: linear-gradient(90deg, #cfb279 0%, #f1dcb1 54.17%, #b39559 100%);
	--bs-btn-border-color: var(--secondary-color-emphasis);
	--bs-btn-hover-bg: transparent;
	--bs-btn-hover-border-color: var(--white-color);
}

.btn-gradient:hover {
	/* background: transparent; */
}

.complaintCard {

	--bs-card-color: var(--color-black);
	--bs-card-title-color: var(--color-black);
	--bs-card-border-radius: 4px;
	--bs-heading-color: var(--color-black);
	--bs-border-radius: 4px;
	--bs-card-spacer-y: 0.625rem;
	--bs-card-spacer-x: 0.625rem;
	--bs-card-border-width: 1px;
	--bs-card-border-color: var(--body-color) !important;
}

.complaintCard .icon {
	background-color: var(--bs-secondary-bg-subtle);
	border-radius: 50px;
	height: 1.875rem;
	width: 1.875rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.fs-3 {
	font-size: 1.25rem !important;
}

.fs-4 {
	font-size: 1.125rem !important;
}

.fs-5 {
	font-size: 0.875rem !important;
}

.fs-6 {
	font-size: 0.75rem !important;
}

.fs-7 {
	font-size: 0.625rem !important;
}

.selectTime {
	height: 112px;
	/* border: 2px solid black; */
	overflow-y: auto;
	/* padding-left:8rem; */
}

.earnCoinTypeTab .nav-tabs {
	--bs-nav-tabs-border-width: 0;
	--bs-nav-link-color: var(--color-white);
	--bs-nav-link-hover-color: var(--accent-color);
	--bs-nav-tabs-link-active-bg: transparent;
	--bs-nav-tabs-link-active-color: var(--accent-color);
	margin-bottom: 1.875rem;

}

.earnCoinTypeTab .nav-tabs .nav-link {
	padding: 0 0 1.125rem;
	position: relative;
	color: var(--color-heading);
	font-weight: bold;
}

.earnCoinTypeTab .nav-tabs .nav-link::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.3125rem;
	background-color: transparent;
	border-radius: 3px;
}

.earnCoinTypeTab .nav-tabs .nav-link.active::after {
	/* background-color: var(--accent-color); */
}

.transaction-logo {
	width: 10%;
	height: auto;
}

.btn-transaction {
	--bs-btn-bg: var(--accent-color);
	--bs-btn-border-color: var(--accent-color);
	--bs-btn-hover-bg: transparent;
	--bs-btn-hover-border-color: var(--accent-color);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: var(--accent-color-emphasis);
	--bs-btn-disabled-border-color: var(--accent-color-emphasis);
}

.btn-earncoin {
	--bs-btn-bg: var(--secondary-color-emphasis-rgb);
	--bs-btn-border-color: var(--secondary-color-emphasis-rgb);
	--bs-btn-hover-bg: transparent;
	--bs-btn-hover-border-color: var(--secondary-color-emphasis-rgb);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: var(--accent-color-emphasis);
	--bs-btn-disabled-border-color: var(--accent-color-emphasis);
}

.earncoinCard {
	--bs-card-bg: var(--primary-color-dark-blue);
	--bs-card-color: var(--color-white);
	--bs-card-border-radius: 10px;
	--bs-card-height: 15.625rem;
	--bs-card-border-color: var(--body-color);
}

.bundleCard {
	background-image: url(../images/avatar-bg.png);
	background-size: cover;
	background-repeat: no-repeat;
	/* mix-blend-mode: difference; */
	border: 1px solid #aaa;
	border-radius: 10px;
}

.dailyLoginDetailsCard {
	/* --bs-card-bg: var(--dark-color); */
	--bs-card-color: var(--color-white);
	--bs-card-title-color: var(--color-white);
	--bs-card-border-radius: 20px;
	--bs-heading-color: var(--color-white);
	--bs-border-radius: 20px;
	--bs-card-spacer-y: 0;
	overflow: hidden;
}

.dailyLoginDetailsCard .card-body {
	max-height: 58.125rem;
	overflow: auto;
	padding-right: 1.5625rem;
	margin-right: -0.625rem;
	width: calc(100% + 0.8125rem);
}

html[dir="rtl"] .dailyLoginDetailsCard .card-body {
	padding-right: 0.725rem;
	margin-right: 0;
	padding-left: 1.5625rem;
	margin-left: -0.625rem;
}

.dailyLoginDetailsCard ul li {
	padding: 1.25rem 0;
	margin-bottom: 0.25rem;
	margin-top: 0.25rem;
	border: 1px solid #707070;
	transition: all 0.3s;
	background-color: var(--dark-color);
}

.dailyLoginDetailsCard ul li:first-child {
	border-radius: 20px 20px 0 0;
}

.dailyLoginDetailsCard ul li:last-child {
	border-radius: 0 0 20px 20px;
}

.dailyLoginDetailsCard ul li:hover {
	background-color: var(--bs-secondary-bg-subtle);
	border-color: var(--bs-secondary-bg-subtle) var(--bs-secondary-bg-subtle) #707070 var(--bs-secondary-bg-subtle);
}

.dailyloginCard {
	--bs-card-color: var(--color-white);
	--bs-card-border-radius: 20px;
	--bs-heading-color: var(--color-white);
	--bs-card-cap-bg: var(--primary-color-dark);
	--bs-border-radius: 20px;
	--bs-card-cap-padding-y: 1.25rem;
	--bs-card-cap-padding-x: 0.9375rem;
	--bs-card-border-width: 0px;
}

.dailyloginCard .card-header {
	border-radius: var(--bs-card-border-radius);
}

/* 
  .earncoinaccordion {
	  --bs-accordion-color: var(--color-white)!important;
	  --bs-accordion-bg: var(--dark-color)!important; 
	  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
	  --bs-accordion-border-color: var(--bs-border-color);
	  --bs-accordion-border-width: var(--bs-border-width);
	  --bs-accordion-border-radius: var(--bs-border-radius);
	  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
	  --bs-accordion-btn-padding-x: 1.25rem;
	  --bs-accordion-btn-padding-y: 1rem;
	  --bs-accordion-btn-color: var(--bs-body-color);
	  --bs-accordion-btn-bg: var(--bs-accordion-bg);
	  --bs-accordion-btn-icon: url(<svg fill="#f3f3f3" height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 511.735 511.735" xml:space="preserve" stroke="#f3f3f3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M508.788,371.087L263.455,125.753c-4.16-4.16-10.88-4.16-15.04,0L2.975,371.087c-4.053,4.267-3.947,10.987,0.213,15.04 c4.16,3.947,10.667,3.947,14.827,0l237.867-237.76l237.76,237.76c4.267,4.053,10.987,3.947,15.04-0.213 C512.734,381.753,512.734,375.247,508.788,371.087z"></path> </g> </g> </g></svg>);
	  --bs-accordion-btn-icon-width: 1.25rem;
	  --bs-accordion-btn-icon-transform: rotate(-180deg);
	  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
	  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
	  --bs-accordion-btn-focus-border-color: transparent;
	  
	  --bs-accordion-btn-padding-x: 1.25rem;
	  --bs-accordion-btn-padding-y: 1.5rem;
	  --bs-accordion-active-color: transparent;
	  --bs-accordion-active-bg: transparent;
  } */

.earncoinaccordion-button:not(.collapsed) {
	color: none;
	background-color: transparent !important;
	box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.earncoinaccordion-item {
	color: var(--color-white) !important;
	background-color: var(--dark-color) !important;
	border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-colo);
}

.earncoinaccordion-item .earncoinaccordion-header .accordion-button {
	/* color: var(--color-white) !important; */
	background-color: var(--dark-color) !important;
	border-radius: 10px;
	/* border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-colo); */
}

.earncoinaccordion-body {
	padding: 0.8rem;
}

.profileBannerCard {
	--bs-card-bg: var(--body-bg);
	--bs-card-color: var(--color-white);
	--bs-card-border-radius: 10px;
	--bs-card-inner-border-radius: 10px;
	--bs-card-border-width: 0px;
}

.profileBannerCard .card-img img {
	height: 300px;
	object-fit: cover;
	width: 100%;
}

.profileBannerCard .card-img::before {
	content: "";
	height: 100%;
	width: 100%;
	background-color: var(--color-black);
	opacity: 0.6;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	border-radius: 10px;
}

.profileBannerCard .card-body {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	padding: 50px;
}

.profileBannerCard .card-buttons {
	max-width: 18.75em;
}

.profileTypeTab .nav-tabs {
	--bs-nav-tabs-border-width: 0;
	--bs-nav-link-color: var(--color-white);
	--bs-nav-link-hover-color: var(--accent-color);
	--bs-nav-tabs-link-active-bg: transparent;
	--bs-nav-tabs-link-active-color: var(--accent-color);
	margin-bottom: 1.875rem;
}

.profileTypeTab .nav-tabs .nav-link {
	padding: 0 0 1.125rem;
	position: relative;
}

.profileTypeTab .nav-tabs .nav-link::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.3125rem;
	background-color: transparent;
	border-radius: 3px;
}

.profilecard {
	background: url(../images/profilecard.png);
	max-width: 800px;
	width: 100%;
	height: 200px;
	border: 1px solid #aaa;
	border-radius: 10px;
	background-size: cover;
}

.profileTypeTab .nav-tabs .nav-link.active::after {
	/* background-color: var(--accent-color); */
}

.profiletitle {
	margin: 1.0625rem 0px 0.9375rem 2.8125rem;
	display: flex;
	align-items: center;
}

html[dir="rtl"] .profiletitle {
	margin: 1.0625rem 2.8125rem 0.9375rem 0;
}

.statisticsCard {
	--bs-card-bg: var(--dark-color);
	--bs-card-color: var(--color-white);
	--bs-card-border-radius: 10px;
	--bs-card-inner-border-radius: 10px;
	--bs-card-border-width: 0px;
}

.text-purple {
	color: var(--primary-color-red);
}

.profileCardBody {
	--bs-card-bg: var(--body-color);
	--bs-card-color: var(--color-white);
	--bs-card-border-radius: 10px;
	--bs-card-inner-border-radius: 10px;
	--bs-card-border-width: 0px;
}

.collgiateInnerCard {
	--bs-card-title-color: var(--dark-color);
	--bs-card-border-width: 0;

	--bs-card-bg: var(--color-white);
	--bs-card-border-radius: 20px;
	--bs-card-spacer-y: 1.25rem;
	--bs-card-spacer-x: 1.25rem;
	--bs-card-color: var(--color-white);
}

.collegiateCard {
	--bs-card-title-color: var(--primary-color);
	--bs-card-border-width: 0;
	--bs-card-bg: var(--dark-color);
	--bs-card-border-radius: 10px;
	--bs-card-spacer-y: 1.25rem;
	--bs-card-spacer-x: 1.25rem;
	--bs-card-color: var(--color-white);
}

.collegiateCard .icon {
	background-color: var(--dark-color);
	border-radius: 50px;
	height: 3.125rem;
	width: 3.125rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.collegiateCard .card-header .card-icon {
	height: 3.125rem;
	padding: 0 2.5rem;
	position: absolute;
	top: -1.875rem;
	left: 50%;
	transform: translateX(-50%);
}

.collegiateCard .icon-col {
	background-color: var(--color-white);
	border-radius: 50px;
	height: 5rem;
	width: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.p-2-3 {
	padding-top: 1rem !important;
}

.playersDetailCard ul .championProfile {
	width: 40px;
	height: 40px;
	background-image: url(../images/championIcon.png);
	background-repeat: no-repeat;
	background-position: right 10px center;
}

html[dir="rtl"] .playersDetailCard ul .championProfile {
	background-position: left 10px center;
}

.playersDetailCard ul .masterProfile {
	background-image: url(../images/grandMasterIcon.png);
	background-repeat: no-repeat;
	background-position: right 10px center;
}

html[dir="rtl"] .playersDetailCard ul .masterProfile {
	background-position: left 10px center;
}

.collegiateCard .icon-col-modal {
	background-color: var(--modal-bg);
	border-radius: 50px;
	height: 5rem;
	width: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* try */

.accordion-item {
	background-color: var(--content-color);

	margin-bottom: 1rem;
	border: none;
	/* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25); */
	border: 0;
	position: relative;
}

.transaction-accordion .accordion-item .accordion-header .accordion-button {
	background-color: var(--primary-color);
	position: absolute;
	margin-top: -1.2rem;
}

.transactionaccordion .accordion-button:not(.collapsed) {
	color: var(--bs-accordion-active-color);
	background-color: var(--primary-color-dark-blue);
	box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.transactionaccordion-item .accordion-button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
	font-size: 1rem;
	color: var(--color-black);
	text-align: left;
	/* background-color: var(--secondary-color-emphasis); */
	border: 1px solid var(--secondary-color-emphasis);
	border-radius: 100px;
	overflow-anchor: none;
	transition: var(--bs-accordion-transition);
}

html[dir="rtl"] .transactionaccordion-item .accordion-button {
	text-align: right;
}

.transactionaccordion .accordion-item:first-of-type .accordion-button {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	/* position: absolute; */
	/* margin-top: -2rem; */
	border-radius: 100px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
	border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
	/* position: absolute; */
	/* margin-top: -2rem; */
	border-radius: 100px;
}

.transactionaccordion .accordion-button:not(.collapsed) {
	color: var(--color-white);
	background-color: var(--primary-color-dark-blue);
	box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
	border-radius: 100px;
	position: absolute;
	margin-top: 0;
}

.transactionaccordion .accordion-body {
	padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
	border-radius: 0 0 10px 10px;
	border: 1px solid var(--secondary-color-emphasis);
	margin-top: 32px;
	background-color: var(--color-white);
	color: var(--color-black);
}

.transactionaccordion .accordion-body-diamond {
	padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
	border-radius: 0 0 10px 10px;
	border: 1px solid var(--secondary-color-emphasis);
	margin-top: 32px;
	background-color: var(--color-white);
	color: var(--color-black);
}

.authModal_transaction {
	--bs-modal-bg: var(--modal-bg);
	--bs-modal-padding: 3.125rem;
	--bs-modal-width: 1000px;
	--bs-modal-border-radius: 20px;
	--bs-modal-border-width: 0;
}

/*-------Transaction -Accordion-------*/
.acc-container {
	width: 100%;
	margin: auto;
	max-height: 450px;
	padding-right: 1rem;
	overflow: auto;
}

html[dir="rtl"] .acc-container {
	padding-right: 0;
	padding-left: 1rem;
}

.acc-container .acc-body {
	width: 98%;
	width: calc(100% - 20px);
	margin: 0 auto;
	height: 0;
	color: rgba(0, 0, 0, 0);
	border-radius: 0px 0px 10px 10px;
	position: relative;
	z-index: 0;
	background: #3e4b5a;
	line-height: 28px;
	padding: 0 20px;
	box-sizing: border-box;
	transition: 0.5s;
	margin-top: -1rem;
}

.acc-tabs {
	margin-bottom: 2rem;
}

.acc-container label {
	cursor: pointer;
	border-radius: 100px;
	background: #4c555f;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	display: block;
	padding: 27px 0;
	width: 100%;
	color: #fff;
	font-weight: 400;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
}

.acc-container input {
	display: none;
}

.acc-container label:before {
	font-family: "FontAwesome";
	content: "\f061";
	font-weight: bolder;
	float: right;
	position: absolute;
	right: 3rem;
	top: 2rem;
}

.acc-container label:before {
	float: left;
	right: inherit;
	left: 3rem;
}

.acc-container input:checked+label {
	background: var(--primary-color);
}

.acc-container input:checked+label:before {
	font-family: "FontAwesome";
	content: "\f063";
	transition: 0.5s;
}

.acc-container input:checked~.acc-body {
	height: auto;
	color: #fff;
	font-size: 16px;
	padding: 30px 42px 15px 25px;
	transition: 0.5s;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

html[dir="rtl"] .acc-container input:checked~.acc-body {
	padding: 30px 25px 15px 42px;
}

.twox {
	border-radius: 4px;
	background: #000;
	width: 41px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 767px) {
	.profileBannerCard .card-body {
		padding: 10px;
	}

	.profileBannerCard .card-img img {
		height: 170px;
	}

	.profilecard {
		height: 150px;
	}

	.gameInfoCard {
		--bs-card-border-radius: 10px;
	}

	.border-start {
		border-left: 0 !important;
	}
}

.other_login {
	margin-top: 1rem !important;
}

.btn-google {
	background-image: url(../new-images/gogoole.png);
	background-position: left 1.25rem center;
	background-repeat: no-repeat;
	text-align: center;
	background-size: 1.875rem auto;
}

.tournamentTypeTab ul li button {
	font-weight: 600;
	color: var(--color-heading);
}

.tournamentTypeTab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: var(--primary-color-red);
}

.tournamentTypeTab .nav-link:focus,
.nav-link:hover {
	color: var(--primary-color-red);
}

.tournametParticipationCard .titleDetails {
	margin-right: -17px;
}

.tournament-accordion .accordion-button {
	background-color: var(--primary-color-dark-blue) !important;
}


.tournament-accordion .accordion-button:not(.collapsed) {
	background-color: transparent;
}

.tournamentRoundCard .card-body {
	border-bottom-left-radius: 1rem;
	border-bottom-right-radius: 1rem;
}

/* .contestWarningDiv {
	margin: 0px -21px;
} */

.contestWarningDiv .col-6 {
	padding-left: 1.2rem;
}

.accordion {
	--bs-accordion-transition: none;
	--bs-accordion-border-color: none;
	--bs-accordion-border-width: none;
	--bs-accordion-border-radius: none;
	--bs-accordion-inner-border-radius: none;
}

.rulesCard .card-body {
	border: 1px solid #707070 !important;
	border-radius: 20px;
}

.dailyloginCard h4,
.dailyloginCard .h4 {
	font-weight: bold !important;
}

.dailyloginCard h4,
.dailyloginCard .h4 {
	font-weight: bold !important;
}

/* .card {
	border-radius: 22px;
	--bs-card-border-color: none !important;
} */

.complaintCard .ticket-card {
	border: 1px solid #707070 !important;
	border-radius: 4px
}

.playersCard .card-header {
	background-color: var(--accent-color-2);
	color: var(--primary-color-dark-blue);
}

.rulesCard ul li::marker {
	color: var(--color-heading);
	font-size: 25px;
}

.subscriptionModalPopup .modal-body {
	background-color: var(--color-black-2);
}

.text-body-color {
	color: var(--body-color) !important;
}

.leaderBoardTab .card-header {
	background-color: var(--primary-color-dark-blue);
}

.premiumUserTournamentModal .modal-body {
	background-color: var(--color-black-2);
}

.premiumUserTournamentModal.tournamentModal h4 {
	color: var(--color-white);
}

.premiumUserTournamentModal.tournamentModal .form-check .form-check-input {
	border: 1px solid var(--color-white);
}

.premiumUserTournamentModal.tournamentModal .form-check {
	border-bottom: 1px solid var(--color-white);
}

.btn-golden {
	background: linear-gradient(90deg, #CFB279 0%, #F1DCB1 54.17%, #B39559 100%) !important;
	color: var(--primary-color-dark-blue) !important;
}

.premiumUserTournamentModal .list-group-item {
	border-bottom: var(--bs-list-group-border-width) solid #ffffff80 !important
}

.premiumUserTournamentModal.modal .btn-close {
	background-color: var(--color-white);
	--bs-btn-close-bg: url(../new-images/cross-circle-golden.svg);
}

.bg-disable {
	background-color: #3E4B5A !important;
	opacity: 0.8;
}

.avatarModal .form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
	opacity: 1 !important;
}

.modal.dark .modal-body {
	background-color: var(--color-black-2);
}

.modal.dark .modal-body label {
	color: var(--color-white) !important;
}

.modal.dark .avatarModal .radioBlock {
	background-color: #70707087 !important;
}

.modal.dark .modal-body .radioBlock label {
	color: var(--primary-color-red) !important;
}

.golden-color .form-control {
	background-color: var(--body-color);
	border: 1px solid var(--body-color);
	color: var(--color-white);
	opacity: 0.5;
}

.collageCardModal .collegiateCard {
	--bs-card-bg: var(--color-white);
}

.collageCardModal .collgiateInnerCard {
	--bs-card-title-color: var(--dark-color);
	--bs-card-border-width: 0;
	--bs-card-bg: var(--primary-color-dark-blue);
	--bs-card-border-radius: 20px;
	--bs-card-spacer-y: 1.25rem;
	--bs-card-spacer-x: 1.25rem;
	--bs-card-color: var(--color-white);
}

.collageCardModal .collgiateInnerCard .card-header.bg-white {
	background-color: var(--primary-color-dark-blue) !important;
}

.collageCardModal .collgiateInnerCard .card-body.color-primary-dark-blue {
	color: var(--color-white) !important;
}

.collageCardModal .collgiateInnerCard .card-body .bodyNormalFont.color-primary-dark-blue {
	color: var(--color-white) !important;
}

#collegiate-tab-pane .css-z2hvri-control {
	background-color: var(--color-white) !important;
	border: 1px solid var(--body-color) !important;
	padding: 0px;
}

#collegiate-tab-pane .css-1ohny0q-control {
	background-color: var(--color-white) !important;
	border: 1px solid var(--body-color) !important;
}

#collegiate-tab-pane .css-1ohny0q-control:hover {
	border: 1px solid var(--body-color) !important;
}

#collegiate-tab-pane .css-1imnxp2-singleValue {
	color: var(--primary-color-dark-blue) !important;
}

#collegiate-tab-pane .css-1nmdiq5-menu {
	/* padding: 0.9375rem; */
	background-color: var(--color-white);
	color: var(--primary-color-dark-blue);
}

.userProfileCard .dividersRightBlue::after {
	content: "";
	height: 2.5rem;
	width: 2px;
	position: absolute;
	right: -1px;
	top: 2px;
	background-color: var(--primary-color-dark-blue);
}

html[dir="rtl"] .userProfileCard .dividersRightBlue::after {
	left: -1px;
	right: auto;
}

.userProfileCard .dividersRightBlue {
	position: relative;
}

.playersCard .dividersRightBlue::after {
	content: "";
	height: 2.5rem;
	width: 2px;
	position: absolute;
	right: -1px;
	top: 2px;
	background-color: var(--primary-color-dark-blue);
}

html[dir="rtl"] .playersCard .dividersRightBlue::after {
	left: -1px;
	right: auto;
}

.playersCard .dividersRightBlue {
	position: relative;
}

.form-control.customFromControll::-webkit-input-placeholder {
	color: #FFF !important;
	opacity: 1;
}

.form-control.customFromControll::-moz-placeholder {
	color: #FFF !important;
	opacity: 1;
}

.form-control.customFromControll:-ms-input-placeholder {
	color: #FFF !important;
	opacity: 1;
}

.form-control.customFromControll:-moz-placeholder {
	color: #FFF !important;
	opacity: 1;
}

@media screen and (max-width: 575px) {
	.rulesCard ul li::marker {
		font-size: 12px;
	}

	.avatar-country {
		align-items: unset !important;
	}

	.avatar-country .css-1dfokj0-option {
		margin-top: 12px
	}

	.avatar-country .css-1dfokj0-option:first-child {
		margin-top: 0
	}

	.avatar-country .css-17h16fv-option {
		margin-top: 12px
	}

	.avatar-country .css-17h16fv-option:first-child {
		margin-top: 0
	}

	.scrim-title {
		margin-bottom: 4px !important;
	}

	.earn-coin-main {
		padding-top: 0 !important;
	}

	.border-start {
		border-left: 0 !important
	}

	.collage-dropdown .css-z2hvri-control {
		padding: 0;
	}

	.transactionaccordion .accordion-body {
		margin-top: 8px !important
	}

	.transactionaccordion .accordion-body-diamond {
		margin-top: 33px !important
	}

	.image-cross-icon {
		width: 20px !important;
		height: 20px !important;
	}

}

@media screen and (max-width: 472px) {
	.rulesCard ul li::marker {
		font-size: 12px;
	}

	.avatar-country {
		align-items: unset !important;
	}

	.avatar-country .css-1dfokj0-option {
		margin-top: 12px
	}

	.avatar-country .css-1dfokj0-option:first-child {
		margin-top: 0
	}

	.avatar-country .css-17h16fv-option {
		margin-top: 12px
	}

	.avatar-country .css-17h16fv-option:first-child {
		margin-top: 0
	}

	.scrim-title {
		margin-bottom: 4px !important;
	}

	.earn-coin-main {
		padding-top: 0 !important;
	}

	.border-start {
		border-left: 0 !important
	}

	.collage-dropdown .css-z2hvri-control {
		padding: 0;
	}

	.transactionaccordion .accordion-body {
		margin-top: 8px !important
	}

	.transactionaccordion .accordion-body-diamond {
		margin-top: 33px !important
	}

	.image-cross-icon {
		width: 20px !important;
		height: 20px !important;
	}
}

.manage-contest {
	--bs-card-border-color: var(--color-heading) !important;
}

.customerCareForm .form-select:disabled,
.customerCareForm .form-control:disabled {
	background-color: var(--secondary-color-emphasis);
	color: var(--color-black);
	border: none;
}

.customerCareForm .custom-input {
	background-color: var(--bs-body-bg);
	color: var(--color-heading);
	/* border: none; */
}

.avatar-country {
	flex-direction: column;
	align-items: center;
}

.scrim-title {
	margin-bottom: 0px;
}

.scrim-gametype-dropsown .css-1cyouzx {
	height: 3.75rem;
	padding: 0.4rem 1.25rem 0 1.25rem;
}

.ticket-category-dropdown .css-12k8tje {
	height: 3.75rem;
	padding: 0.4rem 1.25rem 0 1.25rem;
}

.earn-coin-main {
	padding-top: 3.6rem
}

.image-cross-icon {
	position: absolute;
	top: -10%;
	right: 0;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	cursor: pointer
}

.topProfileCard .btn:focus-visible {
	color: var(--bs-btn-hover-color);
	background-color: var(--primary-color-red);
	border-color: var(--bs-btn-hover-border-color);
	outline: 0;
	box-shadow: var(--bs-btn-focus-box-shadow);
}

.authModule nav {
	z-index: 1051 !important;
}

html[dir="rtl"] .authModule .navbar .container .customDropdown {
	position: relative;
	right: 0;
}

.d11-reward-img img {
	width: 100%;
	object-fit: cover;
	border-radius: 0%;
}

.rewardCard .card-icon img {
	width: 100%;
	object-fit: cover;
	border-radius: 0%;
	height: 6.25rem;
}

.topProfileCard:hover {
	cursor: pointer;
}